body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Verdana', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.leftSideLoginText {
  text-align: center;
  color: white;
}

.leftSideLoginTextMobile {
  text-align: center;
  color: #3e6bb4;
  margin-bottom: -1rem;
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI'), url('../src/assests/fonts/SegoeUI.woff') format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropstart .dropdown-toggle::before {
  display: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-segoeui {
  font-Family: 'Segoe UI';
}

.layout-main-grid {
  padding: 16px;
  margin: 0 !important;
  width: 100% !important;
  height: calc(100% - 64px) !important;
}

@media (max-width: 599px) {
  .layout-main-grid {
    padding: 0;
    margin: 0 !important;
    width: 100% !important;
    height: calc(100% - 56px) !important;
  }
}

.logo {
  width: 156px;
  height: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

.logo-hidden {
  width: 156px;
  height: auto;
  margin-right: auto;
  visibility: hidden;
}

.logo-margin {
  margin-left: 4.5%;
}

.center-logo-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiDataGrid-cell {
  padding: 0px !important;
}

.MuiStepConnector-root {
  display: none !important;
}

.nonActive {
  display: none !important;
}

.cursorGrabbing {
  cursor: move !important;
  /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}

.cursorGrabbing:active {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.stepperNoFlexBox {
  display: block !important;
}

.css-16f07an-MuiButtonBase-root-MuiButton-root:hover {
  background-color: transparent !important;
}

.fullWidth {
  width: 100%;
  min-height: 54px;
}

.maxWidth {
  width: 209px !important;
}

.tastoTop {
  display: flex;
  align-self: flex-start !important;
}

.center-spacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-margin {
  margin: 0 5px !important;
}

.noCardBorderRadius {
  border-radius: 0px !important;
}

.css-b3i956-MuiGrid-root>.MuiGrid-item {
  padding-top: 0 !important;
}

/* .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
font-size: 13px !important;
} */

.fontSizeSmallRadioButton-Mobile {
  font-size: 13px !important
}

@media screen and (max-width: 600px) {
  .MuiDataGrid-virtualScroller {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .css-f3jnds-MuiDataGrid-columnHeaders {
    display: none !important
  }
}

@media screen and (max-width: 600px) {
  .MuiDataGrid-columnHeaders {
    display: none !important
  }
}

@media screen and (max-width: 600px) {
  .css-1nitb7c {
    display: none !important
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.pt-0 {
  padding-top: 0px !important;
}

.buttonBoxToolbar {
  padding: 0 5px 0 0px;
}

.forced {
  max-width: 500px !important;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
}

.flex-bottom-left {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  padding-top: 10px !important;
}

.flex-center-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flex-center-center-text-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.flex-no-center {
  display: flex !important;
  justify-content: center !important;
}

.flex-center-end {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}

.card-icon {
  text-align: center !important;
  padding: 5px 0 0px 0;
}

.card-text-Primary {
  text-align: center !important;
}

.card-text-Secondary {
  text-align: left !important;
}

.cardInfoUtente {
  background: #ececec;
  margin: 0 0 5px 0;
}

.fontCard-Icon {
  font-size: 34px !important;

}

.fontCard-Principal {
  font-size: 14px !important;
}

.fontCard-Secondary {
  margin-Left: 10px !important;
  font-size: 10px !important;
}

.no-margin-right {
  margin-right: 0 !important;
}


.CDCardHeader-Padding {
  padding: 10px !important;
}

.forceCdCardHeader-height {
  height: 55px;
}

.CDCardContent-Mobile {
  background-color: #f2f3f3;

}

.TitleDatable {
  min-Height: 35px;
}

.hiddenClass {
  visibility: hidden;
}

.labelCustom {
  color: rgba(0, 0, 0, 0.6);
  font-family: Segoe UI;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 133%;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, -1.5px) scale(0.75);
  -moz-transform: translate(0, -1.5px) scale(0.75);
  -ms-transform: translate(0, -1.5px) scale(0.75);
  transform: translate(0, -1.5px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.marginToplabel {
  margin-top: 10px;
}

.buttonMenuCollapse {
  margin-Left: 5px !important;
  float: right;
}

.buttonMenuCollapseFirst {
  margin-left: 20px !important;
}

.buttonMenuCollapseExport {
  /*background-color:transparent!important;
  color: rgb(15, 119, 15) !important;*/
}

.buttonMenuCollapseExport:hover {
  color: white !important;
  background-color: rgb(24, 145, 24) !important;
}

.buttonMenuCollapseExport:disabled {
  background-color: rgb(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
  border: none !important;
  color: rgb(0, 0, 0, 0.26) !important;
}

.cardInfoUtente-Click {
  cursor: pointer;
}

.boxElementi-Planimetria {
  background-color: rgba(242, 242, 242, 0.3);
  border: thin solid rgb(234, 230, 230);
  width: 80%;
  height: 50px;
}

.boxElementi-Planimetria-noColorBox {
  width: 80%;
  height: 50px;
}

.boxElementi-Planimetria-overflow {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.boxElementi-legendMap {
  overflow-x: auto;
  overflow-y: hidden;
}

.submenu-separatore {
  /* border-top: 1px solid #EEE  !important; */
  background-color: white;
  margin: 2.5px 5px;
  margin-right: 5px;
  border-radius: 3px;
}

.containerSubmenu {
  background-color: #eee;
}

.containerSubmenu .submenu-separatore:first-of-type {
  margin-top: 5px !important;
}

.containerSubmenu .submenu-separatore:last-of-type {
  margin-bottom: 5px !important;
}

.etichettaFiltroCustom {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.00938em !important;
}

.ElementSuggerimentoPrenotazione {
  border-radius: 3px;
  background-color: #3e6bb4;
  padding: 5px 0px;
  max-width: 140px;
  min-width: 140px;
  max-height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.ElementSuggerimentoZona {
  border-radius: 3px;
  background-color: #3e6bb4;
  padding: 5px 0px;
  max-height: 2rem;
}

@media screen and (max-width: 600px) {
  .ElementSuggerimentoPrenotazione {
    border-radius: 3px;
    background-color: #3e6bb4;
    padding: 5px 0px;
    max-width: 140px;
    min-width: 140px;
  }
}

.ElementSuggerimentoPrenotazione-text {
  color: white;
  font-size: 14px !important;
  display: flex;
  justify-content: right !important;
  text-align: right !important;
  padding-right: 4px;
}

.ElementSuggerimentoZona-text {
  color: white;
  font-size: 12px !important;
}

.boxElementi-SuggerimentoPrenotazione {
  background-color: rgba(242, 242, 242, 0.3);
  border: thin solid rgb(234, 230, 230);
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label-SuggerimentoPrenotazione {
  margin-bottom: 8px !important;
}

.boxElementi-SuggerimentoPrenotazione-column {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.sm-Icon-ElementSuggerimentoPrenotazione-meetingRoom {
  font-size: 70px !important;
  position: absolute;
  right: 1.5rem;
  top: -1.5rem;
  opacity: 15% !important;
  color: white;
  clip: rect(0px, 100px, 100px, 24px);
}

.sm-Icon-ElementSuggerimentoPrenotazione-desktop {
  font-size: 40px !important;
  position: absolute;
  right: 2rem;
  top: 0.1rem;
  opacity: 15% !important;
  color: white;
  clip: rect(0px, 100px, 45px, 6px);
}

.sm-Icon-ElementSuggerimentoPrenotazione-cafeteria {
  font-size: 40px !important;
  position: absolute;
  right: 2.5rem;
  top: 0.1rem;
  opacity: 15% !important;
  color: white;
  clip: rect(0px, 100px, 45px, 6px);
}

.sm-Icon-ElementSuggerimentoPrenotazione-park {
  font-size: 48px !important;
  position: absolute;
  right: 2.3rem;
  top: -0.2rem;
  opacity: 15% !important;
  color: white;
  clip: rect(0px, 100px, 45px, 8px);
}

.sm-icon-2-ElementSuggerimentoPrenotazione {
  margin-left: 4px !important;
  height: 17px !important;
  width: 25px !important;

}

.sm-dialogRiepilogo-tasti {
  display: flex;
  justify-content: end;
}

.button-paddingLeft {
  margin-left: 8px !important;
  margin-bottom: 5px !important;
}

.border-Content-dialog {
  margin-bottom: 5px !important;
  border-bottom: 0.5px solid #eee;
}

.border-Action-dialog {
  margin-top: 5px !important;
  border-top: 0.5px solid #eee;
}

.PrivateDateTimePickerToolbar-penIcon {
  display: none !important;
}

.qrCode-CoreElement {
  max-width: 88%;
  margin-left: 6%;
  margin-right: 6%
}

.qrCode-icon-warning {
  color: #ffc107;
  padding: 0px 10px;

}

.qrcode-text-warning {
  font-size: 14px !important;
  padding: 0px 8px !important;
}

.MuiBackdrop-root {
  background-color: #08080870 !important;
}

.MuiBackdrop-invisible {
  background-color: #08080870 !important;
}

.stepper-grid {
  padding-top: 10px !important
}

.font-w500 {
  font-weight: 500 !important;
}

.iconStepper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 23px;
  width: 23px;
  background-color: #3e6bb4;
  border-radius: 50%;
  font-size: 0.75rem !important;
}

.iconStepper-off {
  background-color: rgba(0, 0, 0, 0.38);
}

.italic {
  font-style: italic;
}

.buttonSincro {
  color: #3e6bb4 !important;
  display: flex !important;
  justify-content: inherit !important;
  align-self: self-end !important;
  cursor: pointer !important;
  padding: 20px 0 0 5px !important;
}

.textOverflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-primary {
  color: #3e6bb4 !important;
}

.custom-spinner {
  /* background-color: transparent !important; */
  background-color: #ffffff50 !important;
}

.box-shadow-light {
  box-shadow: 0 1px 8px rgb(0 0 0 / 15%) !important;
}

.no-box-shadow {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
}

.margin-row-lista {
  margin: 6px 0px
}

.margin-row-lista:first-child {
  margin: 0 0px 6px 0px
}

.no-box-shadow {
  box-shadow: 0 0px 0px !important;
}

.row-lista-effect {
  border-image: repeating-linear-gradient(135deg, white 0%, white 0.5%, #dc3545 0.5%, #dc3545 1%) 3
}

.dialogPreferenzeTitle {
  padding-left: 0px !important;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #AA0202;
  border-color: #AA0202;
  border-radius: 20px;
  box-shadow: inset 0 0 0px rgb(0, 0, 0) !important;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0px rgb(0, 0, 0) !important;
}

body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0px rgb(0, 0, 0) !important;
}

body::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  /*background-color: #AA0202;
  outline: 1px solid #AA0202;*/
  background-color: #c0c0c0;
  outline: 1px solid #c0c0c0;
  border-radius: 20px;
  box-shadow: inset 0 0 0px rgb(0, 0, 0) !important;
}

.accordion {
  margin-bottom: 10px;
}

.accordion-question {
  font-size: 1.15rem !important
}

.accordion-answer {
  color: rgb(62, 107, 180);
  font-size: .875rem !important;
}

.body-accordion {
  padding: 16px !important;
  background-color: #f6f6f6;
}

.small-padding-mobilerow {
  padding: 16px 10px 16px 10px !important;
}

.flexEnd {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.leftContainer {
  min-width: 330px;
  min-height: 80vh;
}

@media screen and (max-width: 1200px) {
  .leftContainer {
    min-height: 20vh !important;
  }
}

.chip-square {
  border-radius: 6px !important;
  margin: 2px !important;
  height: 24px !important;
  font-size: 12px !important;
}

.amenities {
  position: absolute;
  width: 6px;
  height: 6px;
  cursor: default;
}

.rounded {
  border-radius: 50% !important;
}

.square {
  border-radius: 25% !important;
}

.amenities-box {
  margin: 10px 0 10px 0 !important
}

.amenities-box-title {
  font-Size: 10px !important;
  margin-Top: 2px !important;
}

.amenities-infoitem {
  width: 8px;
  height: 8px;
  margin-Right: 5px;
}

.container-stepper-datapickerbox {
  padding-bottom: 40px;
  padding-top: 40px;
}

.box-stepper-reserve-and-ospiti {
  padding-left: 40px;
}

.select-stepper-reserve {
  padding-bottom: 20px;
}

.box-stepper-guests {
  padding-top: 15px;
}

.cardRiepilogo {
  display: flex;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #ccc;
}

.cardRiepilogoText {
  color: #3e6bb4;
  font-size: 20px !important;
  font-weight: 650 !important;
}

.cardRiepilogoIcon {
  max-width: 1rem !important;
  text-align: center !important;
  margin-right: 0.7rem !important;
}

.cardRiepilogoDetailsText {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.infoIcon {
  color: darkgrey !important;
}

.avatarIcon {
  height: 50%;
  width: 50%;
}

.padding-left-fullnameUtente {
  padding-left: 5px;
}

.typo-room-datatable {
  text-transform: capitalize;
  font-size: 14px !important;
}

.stepper-edificio-close {
  filter: grayscale(1)
}

.boxAutoCompletePills {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #f1efef;
  background-color: #f9f9f9;
  min-height: 24px;
  justify-content: center;
  align-items: center;
}

.labelAutoCompletePills {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem !important;
  transform: scale(0.75) !important;
  transform-origin: left;
}

.pillsAutoCompletePills-selected {
  background-color: #3e6bb4 !important;
  color: #fff !important;
}

.containerAutoCompletePills {
  margin-top: 5px;
}

.info-text {
  font-Size: 12px !important;
  color: #3e6bb4 !important;
}

.element-msg-containerPills {
  text-align: center;
  margin-top: 5px !important;
  color: rgb(192 187 187);
}

.no-overflow {
  overflow: hidden !important;
}

.overflow-y {
  overflow-y: auto !important;
}

.overflow-x-none {
  overflow-x: none !important;
}

.fixedButtonsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.height-tot {
  height: 100%;
}

.container-map {
  /* background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .5) 3px, rgba(255, 255, 255, .5) 6px) !important; */
  background-image: repeating-linear-gradient(135deg, white, transparent 3px, #fafafa 1px, #eeeeee 6px) !important
}
.riepilogoPrenotazioneButtonsContainer {  
  background-color: #fff;
  padding: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between !important;
}

.boxElementi-AccountPersonale-overflow {
  overflow-x: hidden;
  overflow-y: auto;
}

.boxElementi-AccountPersonale {
  background-color: rgba(242, 242, 242, 0.3);
  border: thin solid rgb(234, 230, 230);
  width: 80%;
  height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
