.field-DialogRiepilogo {
    font-size: 13px;
    font-weight: 600;
}

.icon-color {
    color: #3e6bb4;
}

.paper-DialogRiepilogo {
    background-color: #f9f9fa !important;
    height: 100% !important;
    border-radius: 4px 0 0 4px !important;
    margin-top: 8px;
    padding-top: 21px;
}

.box-shadow-light-paper-Riepilogo {
    box-shadow: 0 1px 8px rgb(0 0 0 / 15%) !important; 
  }

.flex-center {
    display: flex;
    align-items: center;
}

.flex-center-center {
    display: flex;
    justify-content: right;
    align-items: center;
}

.line-spacing {
    margin:0px 10px 5px 5px;
}

.icon-spacing-right {
    margin-right: 5px;
}

.icon-success {
    color:#28a745
}

.icon-error {
    color:#dc3545
}

.no-padding-top {
padding-top: 0 !important;
}


/*background-color: #f9f9fa !important;*/