.row-planner {
    padding: 6px !important;    
    color: #424242;
    height: fit-content;
}

.planner-card-left-section {
    background-color: #f9f9fa !important;
    border-radius: 4px 0 0 4px !important;
    min-width: 110px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: left !important;
}

.containerRow-planner {
    height:100%;
    width:22px;
    display:block;
    position:absolute;
    left:50%;
    transform: translate(-50%,0)
}

.paddingRow-planner {
    padding:10px 0 ;
}

.background-weekend {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, .5) 3px, rgba(255, 255, 255, .5) 6px) !important;
}

.contextMenu-planner {
    background-color: transparent !important;
    /* opacity: -1 !important; */
}

.titleMenu-planner {
    font-size: 17px !important;
    font-weight: 800 !important;
}

.textMenu-planner {
    font-size: 14px !important;
}
