.cardDisponibilita {
    background-Color: transparent !important;
    width: 100%;
    border-radius: 4px !important;
}

.rowPathDisponibilita {
    margin: 15px 10px;
    padding: 5px 0;
}

.rowDisponibilitaCardTitle {
    margin: 8px 10px;
    padding: 5px 0;
}

.MuiPickersDay-root:hover {
    background-color: #cfcfcf !important;
    color: #fff
}

.MuiPickersDay-root:focus {
    background-color: #3e6bb4 !important;
    color: #fff
}

.pathIcon {
    color: #3e6bb4 !important;
}

.textPath {
    color: #3e6bb4 !important;
    padding-left: 2px !important;
    font-weight: 500 !important;
}

.pathElement {
    padding-left: 5px !important;
}


.rb {
    float: right;
}

.textDisponibilta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectedElement {
    background-color: #3e6bb4 !important;
    color: #fff !important;
}

.MuiSlider-markLabelActive {
    font-weight: 500 !important;
    color: #3e6bb4 !important;
}

.MuiSlider-markLabel {
    font-size: 12px !important;
}

@media screen and (max-width: 600px) {
    .MuiSlider-markLabel {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 600px) {
    .no-padding-MuiCardContent-root {
        padding: 16px 0 0 0 !important;
    }
    .no-padding-top-MuiCardContent-root {
        padding: 0 0 0 0 !important;
    }
}

/*---------------- (Andrea) nuovi stili dashboard ---------------------------*/

/*override altezza min su calendar-picker previene overflow con scrollbar su mesi a 31 giorni*/
.PrivatePickersSlideTransition-root {
    min-height: 230px !important;
}

/*right-border come divider verticale tra calendar e lista prenotazioni*/
.with-right-divider {
    border-right: 1px solid lightgrey;
    padding-right: 12px;
}
@media screen and (max-width:1200px) {
    .with-right-divider{
        border-right: 0;
    }
}

.Lpad-12 {
    padding-left: 12px;
}

/*contenitore button mostra prenotazioni e prenota*/
.calendar-bottom-btn-container {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

/*button sotto calendario dashboard*/
.btn-w-right-icon {
    width: 100%!important;
    min-width: 320px!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    margin: 4px !important;
}

.container-prenotazioni{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left:10px;
    width: 100%;
}

/*pulsante mostra altro*/
.show-more{
    margin-top: 8px!important;
    margin-right: 8px!important;
    height: fit-content;
    justify-content: space-between;
    width: fit-content!important;
    min-width: 180px!important;
}
.show-more-mob{
    height: fit-content;
    padding: 10px 0!important;
    justify-content: center!important;
    margin-top: 12px!important;
    margin-bottom: 40px!important;
    margin-right: 12px!important;
    margin-left: -2px!important;
}

.no-result-card{
    text-align: center;
    justify-content: center;
    align-self: center;
    padding-bottom: 10px !important;
}

.no-result-icon{
    font-size:80px !important;
    color: #F9F9FA;
}

.no-result-text{
    font-size: 23px !important;
    font-weight: 500;
    font-style: italic;
    color: #3e6bb4;
}

.bc{
    background-color: white;
}