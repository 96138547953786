.row-mobileView {
    margin: 6px 0 6px 0 !important;
    width: 100%;
    color: #424242 !important;
    height: fit-content;
    cursor: pointer;
}

.firstRow-mobileView {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    margin-top: 0.5px;
}

.secondRow-mobileView {
    font-size: 12px; margin: 0; 
    margin-top: 4px;
}

.thirdRow-mobileView {
    font-size: 12px; margin: 0; 
    margin-top: 4px;
}

.mobileRow-center-text {
    max-width: none !important;
}

.forceNoPaddingLeft {
    padding-left: 0px !important;
}