.culture-button-container {
    display: flex;
    justify-content: center;
    gap: 20px; 
  }
  
  .culture-button {
    width: 100px; 
    height: 100px;
    border-radius: 50%; 
    background-size: cover; 
    background-position: center center;
    border: 1px solid;
    border-color: grey;
    cursor: pointer;
  }
  
  .culture-size {
    width: 20px;
    height: 20px;
  }