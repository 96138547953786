.row-prenotazione {
    margin: 8px !important;
    padding-right: 0px!important;
    min-width: 400px;
    min-height: 90px;
    width: 100%;
    color: #424242 !important;
    height: fit-content;
    cursor: pointer;
}

.row-mobile {
    margin-left: -12px !important;
    min-width: 330px;
}

/*stile per il contenitore della prenotazione con timeline per display desktop*/
.row-timeline-container {
    display: flex;
    flex-direction: column;
}

/*box orario in card prenotazione*/
.booking-card-left-section {
    background-color: #f9f9fa !important;
    height: 100% !important;
    border-radius: 4px 0 0 4px !important;
    min-width: 110px;
    padding-left: 5px;
    padding-right: 5px;
}

/*box icona postazione card prenotazione mobile*/
.bk-icon-mob {
    min-width: 50px;
    max-width: 50px;
}

@media (max-width: 400px) {
    .bk-icon-mob {
        /* min-width: 30px !important; */
        min-width: 40px !important;
        /* max-width: 30px !important; */
        max-width: 40px !important;
    }
  }


/*stile icona postazione card mobile*/
.bk-icon {
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    font-size: 35px !important;
    padding: 5px;
}
@media (max-width: 400px) {
    .bk-icon {
        border-radius: 50% !important;
        border-style: solid !important;
        border-width: 2px !important;
        font-size: 28px !important;
        padding: 3px !important;
    }
  }

/*stile icona meetingroom card mobile*/
.bk-icon-light {
    color: #3e6bb4;
    border-color: #3e6bb4;
}

/*stile icona postazione card mobile*/
.bk-icon-fill {
    color: white;
    background-color: #3e6bb4;
    border-color: #3e6bb4;
}

.prenotaz-14pt {
    font-size: 14px;
    margin: 0;
}

/*stili riga user prenotazione*/
.prenotaz-15pt {
    font-size: 14px;
    margin: 0;
    /* color: #9eaac7 */
    color: #424242
}

.utente-ins-icon {
    margin-bottom: -7px !important;
    margin-right: 5px !important;
    margin-left: 7px !important;
    margin-top: 0 !important;
    width: 20px !important;
}

/* evita overflow margine destro pagina in mod. mobile
.MuiGrid-root {
    margin-right: 3px;
} */

/*blocco data prenotazione mobile*/
.bk-date-mobile {
    color: #3e6bb4;
    font-size: 15px;
    text-align: center;
    padding: 0;
    /* min-width: 60px; */
}

.count-prenotazioni-utente {
    font-size: 12px !important;
    font-style: italic;

}

.timeItemCenterText {
    font-size: 1.3rem !important;
}

.textPostazione {
    text-align: center;
    font-size: 20px !important;
}

.textZona {
    text-align: center;
    font-size: 15px !important;
}

.textNominativo {
    font-weight: 650 !important;
    font-size: 20px !important;
}

.textOrario {
    color: #3e6bb4;
    font-size: 20px !important;
    font-weight: 650 !important;
    padding: 0;
    text-align: center;
}

.styleOspite {
    display: inline;
    color: grey;
}