.cardDisponibilita {
    background-Color: transparent !important;
    width:100%;
    border-radius:4px !important;

}

.rowPathDisponibilita {
        margin: 15px 10px;
        padding: 5px 0;
}

.rowDisponibilita {
    margin: 15px 10px;
    /* padding: 5px 0; */
}

.rowDisponibilitaCardTitle {
    margin: 8px 10px;
    padding: 5px 0;
}

.cardDisponibilitaSigla {
background-color: #F9F9FA !important;
height: 100% !important;
border-radius: 4px 0 0 4px !important;
}

.MuiPickersDay-root:hover {
    background-color: #cfcfcf !important;
        color:#fff
}

.MuiPickersDay-root:focus {
    background-color: #3e6bb4 !important;
        color:#fff
}


.pathIcon {
    color: #3e6bb4 !important;
}

.textPath {
    color:#3e6bb4 !important;
    padding-left:2px !important;
    font-weight: 500 !important;
}

.pathElement {
    padding-left:5px !important;
}


.rb {
float: right;
}

.textDisponibilta {
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}


.selectedElement {
    background-color: #3e6bb4 !important;
     color: #fff !important;
}

.MuiSlider-markLabelActive {
    font-weight: 500 !important;
    color: #3e6bb4 !important;
}

.MuiSlider-markLabel {
    font-size: 12px !important;
}

@media screen and (max-width: 600px) {
    .MuiSlider-markLabel {
        font-size: 10px !important;
    }
  }

  @media screen and (max-width: 600px) {
  .no-padding-MuiCardContent-root {
    padding:16px 0 0 0 !important;
  }
}

.faqGridContainer{
    padding: 16px 10px 16px 10px !important;
}
.PrivatePickersSlideTransition-root {
    min-height: 230px !important;
}

.fc .fc-toolbar.fc-header-toolbar{
    display: none!important;
}.fc-timeline-event:not(.fc-event-start):before {
    border-color: transparent #fffafa!important;
    margin-right: 10px!important;
}
.fc-timeline-event:not(.fc-event-end):after{
    border-color: transparent #fffafa!important;
    margin-left: 10px!important;
}
.fc .fc-timeline-header-row-chrono .fc-timeline-slot-frame {
    justify-content: center!important;
}
.fc .fc-scroller-liquid-absolute{
    overflow: auto!important;
}
.fc .fc-resource-timeline .fc-resource-group:not([rowspan]){
    background-color: #fafafa!important;
}
.fc .fc-datagrid-cell-cushion {
    font-family: 'Segoe UI'!important;
}
.fc .fc-timeline-slot-cushion{
    color: #787272!important;
}
.fc-h-event .fc-event-title{
    font-family: 'Segoe UI'!important;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root{
    box-shadow: none!important;
}
.fc-datagrid-cell{
    width: 10px!important;
}