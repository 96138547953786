.allineamentoCampi {
    /* display: flex ; */
    align-items: center;
    /* justify-content: center; */
     margin: 15px 0;
}

.allineamentoTitle {
    display: flex ;
    align-items: center;
    justify-content: left;
    margin-top: 15px;
}

.allineamentoButton {
    display: flex;    
    align-items: flex-end;
    justify-content: flex-end;
}

.baseWord {
    color: #3e6bb4;
    font-size: 15px;
    font-weight: bold;
}

.CardWordInput {
    /* margin-right: 20px; */
    font-size:16px;
}

.titleCard {
    color: #3e6bb4;
    font-size: 18px;
    font-weight: bold;
}

.errorFilterData {
    color: rgb(211, 27, 27);
    font-weight: bold;
    margin: 30px 0 0 30px;
}


.card{
    margin-top: 30px;
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.titleFilter {
    margin: 15px 0 0 30px;
    font-size: 28px;
}

.FilterShortCut {
    width: 400px;
    padding-top: 7px;
    display:flex;
    justify-content:space-between
}

.buttonGrid {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.buttonStyle {
    background-color: #3e6bb4 !important;
}
