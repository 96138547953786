.generalElement {
cursor: pointer;
display: flex;
justify-Content:center;
position: absolute;
top:0px;
left:0px;
background: #3e6bb4;
border-Radius: 50%;
box-Shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
padding: 5px;
/* height: "38px";
width: "38px"; */
color: white;
border: 2px solid white
}

.PostazioneBusy {
    cursor: pointer;
    display: flex;
    justify-Content:center;
    position: absolute;
    top:0px;
    left:0px;
    background: #ad3a3a;
    border-Radius: 50%;
    box-Shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 5px;
    /* height: "38px";
    width: "38px"; */
    color: white;
    border: 2px solid white
}

.PostazioneDisabled {
    display: flex;
    justify-Content:center;
    position: absolute;
    top:0px;
    left:0px;
    background: #979797;
    border-Radius: 50%;
    box-Shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 5px;
    /* height: "38px";
    width: "38px"; */
    color: white;
    border: 2px solid white
}

.Postazionereserved {
    display: flex;
    justify-Content:center;
    position: absolute;
    top:0px;
    left:0px;
    background: #979797;
    border-Radius: 50%;
    box-Shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 5px;
    /* height: "38px";
    width: "38px"; */
    color: white;
    border: 2px solid white
}

.dontshow {
    visibility: hidden;
    overflow: hidden;
}