.Use
{
    cursor: pointer !important;
}

.firstRow-HeaderMobile {
    font-weight: bold;
    font-size: 15px;
    margin: 0;
    margin-left : 10;
    margin-top: 0.5px;
}

.secondRow-HeaderMobile {
    font-size: 12px; margin: 0; 
    margin-left : 10;
    margin-top: 4px;
}

.thirdRow-HeaderMobile {
    margin: 10px 0 -5px 0;
}

.sm-Icon {/*base icon and building*/
    margin-left: 4px !important;
    margin-bottom: -3px !important;
    color: #3e6bb4 !important;
    height: 18px !important;
    width: 27px !important;
}

.sm-icon-2 {/*meetingroom*/
    margin-left: -1px !important;
     height: 17px !important;
     width: 25px !important;
}

.sm-icon-3 { /*location pin*/
    margin-left: 0px !important;
    margin-right: -4px !important;
     height: 17px !important;
     width: 25px !important;
     
}

.sm-icon-force { /*building*/
    margin-left: 7px !important;
    margin-bottom: -3px !important;
    color: #3e6bb4 !important;
    height: 18px !important;
    width: 19px !important;
}

.sm-icon-4 {/*user icon*/
    margin-left: 5px !important;
    margin-right: 3px !important;
     height: 17px !important;
     width: 25px !important; 
}

.sm-Icon-margin {
    margin-right: 5px;
}