.arrowContainer {
    display: flex;
    align-items: center;
}

.arrow {
    font-size: 1.5rem;
    vertical-align: text-top;
    color: rgba(0, 0, 0, 0.4);
}

.biggerArrow {
    font-size: 2rem !important;
}

.longDescriptionText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ellipsis-text{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
}

.limit-width-zona {
    max-width: 150px;
    text-align: left
}

.timeItemLeft {
    display: flex;
    align-items: center;
    text-align: left;
    vertical-align: middle;
    justify-content: flex-start;
}

.timeItemLeftText {
    font-size: 1rem !important;
    color: black;
}

.timeItemCenter {
    display: flex;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
}

.timeItemCenterText {
    font-size: 1.3rem !important;
}

.timeItemRight {
    display: flex;
    align-items: center;
    text-align: right;
    vertical-align: middle;
    justify-content: flex-end;
}

.timeItemRightText {
    font-size: 1rem !important;
    color: black;
}

.avatarNameContainer {
    display: flex;
    align-items: center;
}

.avatarImage {
    width: 40px !important;
    height: 40px !important;
}

.avatarName {
    margin-left: 1rem !important;
    font-size: 1.2rem !important;
}

.grayLine {
    position: relative;
}

.grayLine::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(204, 204, 204, 0.5);
}

.pianoSection {
    background: #e9eef2;
    border-radius: 4px;
    max-width: 20%;
}

.pillTag {
    background: #e9eef2;
    border-radius: 4px;
    min-width: 60px;
}

.pianoText {
    color: #afbbc9; 
    font-weight: bold;
    text-align: center;
}

.checkInTag {
    border-radius: 4px;
    max-width: 20%;
}

.dashed-divider {
    width: 100%;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.12);
    position: relative;
    bottom: 0.7rem;
    text-align: center;
  }

.iconBuilding {
    color: rgba(102, 102, 102, 0.2) !important;
}

.iconOspite {
    color: #3e6bb4!important;
}
