
.elementCommonInStepper {
    border: 2px solid !important;
    border-color: #00000014 !important;
}

.elementSelectedInStepper {
    border: 2px solid !important;
    border-color: #4876c0de !important;
}

.elementAssociatedInStepper{
    border: 2px solid !important;
    border-color: #28a745 !important;
}