/*stile base per il connettore verticale tra un bullet e un'altro della timeline*/
.timeline-connector {
    background-color: lightgrey;
    min-width: 2px;
    height: 100%;
}

/*stile invisibile per segmento connettore timeline di primo/ultimo elemento lista,
 renderizza connettore invisibile senza alterare layout */
.timeline-connector-hide {
    background-color: transparent;
    /*mantenere il margin uguale a timeline-connector-top/bottom anche se invisibile 
    per non alterare altezza complessiva dell'oggetto e decentrare il layout */
    margin-top: 7px;
    height: 100%;
}

/*segmento connettore timeline sopra icona*/
.timeline-connector-top {
    margin-bottom: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    justify-self: start;
}

/*segmento connettore timeline sotto icona*/
.timeline-connector-bottom {
    margin-top: 7px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-self: end;
}

/*stile base per bullet timeline senza icona*/
.default-timeline-bullet {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: lightgrey;
}

/*forma base per bullet della timeline*/
.timeline-icon {
    height: fit-content;
    padding: 4px;
    border-radius: 50%;
}

/*stile icona timeline bianco per sala riunione*/
.tl-icon-light {
    color: #3e6bb4;
    border-color: #3e6bb4;
    border-style: solid;
    border-width: 2px;
}

/*stile icona timeline blu per postazione*/
.tl-icon-fill {
    color: white;
    border-color: #3e6bb4;
    background-color: #3e6bb4;
    border-style: solid;
    border-width: 2px;
}

.tl-icon-fill-disabled {
    color: #9e9d9d;
    border-color: #9e9d9d;
    background-color: #F9F9FA;
    border-style: solid;
    border-width: 2px;
}

.timeline-bullet-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px;
    height: 100%;
}

.tl-icon-mensa {
font-Size:16px;
padding: 0 1px;
}

.tl-icon-parcheggio {
font-Size:16px;
padding: 0 1px;
}

.tl-icon-riunione {
font-Size:16px !important;
}

.tl-icon-ufficio {
font-Size:14px;
padding: 1px 0;
}    

/*contenitore elemento timeline*/
.timeline-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    /* flex-grow:1;*/
}

/*stile data/ora lato sinistro della timeline*/
.bullet-date-label {
    display: flex;
    align-items: flex-end;
    align-content: center;
    justify-content: center;
    min-width: 60px;
    color: #666;
}